.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes scale {
  50% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

h1 {
  background: linear-gradient(
    270deg, #dec0f1ff, #7161efff
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

h5 {
  color: #957fefff;
}

.loader{
  display: inline-block;
  width: 250px;
  height: 20px;
  border-radius: 40px;
  background-color: white;
  position: relative;
  overflow: hidden;
}

.loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 150%;
  height: 100%;
  background-image: 
    linear-gradient(270deg, #dec0f1ff, #7161efff);
  border-radius: inherit;
  transform: scaleX(0);
  transform-origin: left;
  animation: scale 2s infinite;
}

.support-ukraine, .support-ukraine:visited {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: #282c34;
	display: flex;
	justify-content: center;
	padding-top: 5px;
	padding-bottom: 5px;
	z-index: 10000;
	text-decoration: none;
	font-family: arial;
}
.support-ukraine:hover, .support-ukraine:active {
	display: flex;
	text-decoration: none;
}
.support-ukraine__flag {
	height: 25px;
	margin-right: 10px;
}
.support-ukraine__flag__blue {
	width: 40px;
	height: 12.5px;
	background: #005BBB;
}
.support-ukraine__flag__yellow {
	width: 40px;
	height: 12.5px;
	background: #FFD500;
}
.support-ukraine__label {
	color: #dec0f1ff;
	font-size: 18px;
	line-height: 25px;
}

.support-ukraine__firstlabel {
  padding-right: 10px;
	color: white;
	font-size: 18px;
	line-height: 25px;
}